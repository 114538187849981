import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { Certificate, Member } from '../common/interfaces';
import { team } from '../common/data';
import { ViewportScroller } from '@angular/common';
import { certificates } from '../common/certificates';

@Component({
  selector: 'app-member',
  imports: [],
  templateUrl: './member.component.html',
  styleUrl: './member.component.css'
})
export class MemberComponent implements OnInit {
  member!: Member;
  certificates: Certificate[] = [];
  constructor(
    private route: ActivatedRoute,
    private viewportScroller: ViewportScroller,
  ) {
    this.viewportScroller.scrollToPosition([0, 0]);
  }

  ngOnInit(): void {
    this.route.params.subscribe(
      params => {
        //this.id = params['id'];
        //console.log(params['id'])
        this.getMemberById(params['id']);
      }
    )
  }

  getMemberById(id: string) {
    for(let i = 0; i < team.length; ++i) {
      if(team[i].id === id) {
        this.member = team[i];
        //console.log(this.member)
        if(this.member.id === 'alberto-toscani') {
          this.certificates = certificates;
        }
        return;
      }
    }
  }
}
