import { ViewportScroller } from "@angular/common";
import { Component } from "@angular/core";
import { Router } from "@angular/router";
import { SharedService } from "../shared.service";

@Component({
  selector: 'app-footer',
  imports: [],
  templateUrl: './footer.component.html',
  styleUrl: './footer.component.css'
})
export class FooterComponent {
  year: number = new Date().getFullYear();

  constructor(
    private router: Router,
    private viewportScroller: ViewportScroller,
    public shared: SharedService
  ) {

  }
  navigate(dest: string) {
    this.router.navigate([dest]);
    this.viewportScroller.scrollToPosition([0, 0]);
  }
}
