<nav class="navbar navbar-expand-lg bg-nav sticky-top shadow">
    <div class="container-fluid">
        
        
        <a class="navbar-brand clickable" (click)="shared.navigate('home')">
            <img class="img-fluid" src="https://albertotoscani.it/images/logos/logo-nav-1.jpg" alt="navbar logo albertotoscani.it" style="height: 50px;">
        </a>
        <button class="navbar-toggler" type="button" data-bs-toggle="offcanvas" data-bs-target="#offcanvasNavbar" aria-controls="offcanvasNavbar" aria-label="Toggle navigation">
            <span class="navbar-toggler-icon"></span>
        </button>
        
        <div class="offcanvas offcanvas-end" tabindex="-1" id="offcanvasNavbar" aria-labelledby="offcanvasNavbarLabel">
            <div class="offcanvas-header">
                <a class="navbar-brand clickable " (click)="shared.navigate('home')">
                    <img class="img-fluid" src="https://albertotoscani.it/images/logos/logo-nav-1.jpg" alt="navbar logo albertotoscani.it" style="height: 50px;">
                </a>
                <button type="button" class="btn-close" data-bs-dismiss="offcanvas" aria-label="Close"></button>
            </div>
            <div class=" offcanvas-body" >
                <ul class="navbar-nav justify-content-end flex-grow-1 pe-3">
                    <li class="nav-item m-1"><a class="nav-link btn" [class.active]="shared.activeLink==='home' || shared.activeLink==''" (click)="shared.navigate('home')">Home</a></li>
                    <li class="nav-item m-1"><a class="nav-link btn" [class.active]="shared.activeLink==='team'" (click)="shared.navigate('team')">Team nutrizionale</a></li>
                    <li class="nav-item m-1"><a class="nav-link btn" [class.active]="shared.activeLink==='collab'" (click)="shared.navigate('collab')">Collaborazioni</a></li>
                    <li class="nav-item m-1"><a class="nav-link btn" [class.active]="shared.activeLink==='services'" (click)="shared.navigate('services')">Servizi</a></li>
                    <li class="nav-item m-1"><a class="nav-link btn" [class.active]="shared.activeLink==='community'" (click)="shared.navigate('community')">Community</a></li>
                    <li class="nav-item m-1"><a class="nav-link btn" [class.active]="shared.activeLink==='contacts'" (click)="shared.navigate('contacts')">Contatti</a></li>
                </ul>
            </div>
        </div>
    </div>
</nav>
