<h1 class="display-4 fw-bold lh-1 text-body-emphasis m-3">Il nostro Team Nutrizionale</h1>

<p class="fs-4 m-3">
  Ogni professionista è specializzato in un ambito specifico della nutrizione. Scopri chi può aiutarti al meglio nel tuo percorso!
</p>

<div class="container">
  <div class="row justify-content-center">
    @for (member of team; track $index) {
      <div class="col-lg-4 d-flex justify-content-center p-3">
        <div class="card border-0 img-service shadow layers m-2" style="max-width: 400px; width: 100%;">
          <!-- Logo di sfondo -->
          <img class="img-fluid shadow bg-image" src="https://albertotoscani.it/images/logos/albertotoscani-logo.png" alt="Logo Studio Toscani" loading="lazy" />
          
          <!-- Foto del membro del team -->
          <img class="img-fluid shadow member-image" src="{{member.imageUrl}}" alt="{{member.name}}" loading="lazy" />

          <div class="card-img-overlay text-center d-flex flex-column justify-content-end image-overlay">
            <div class="mx-2 p-3">
              <p class="text-white card-title text-center service">
                {{member.descr}}
              </p>
              <p class="card-title text-center service">
                <button class="btn btn-primary btn-lg shadow m-1" (click)="shared.navigate('team/member/'+member.id)">
                  Scopri di più
                </button>
              </p>
            </div>
            <h5 class="text-white fw-bold">
              {{member.name}}
            </h5>
          </div>
        </div>
      </div>
    }
  </div>
</div>

<div class="p-3">
  <p class="text-center fs-4 m-3">
    Non sai quale nutrizionista faccia al caso tuo? Contattaci per una consulenza personalizzata.
  </p>
  <div class="text-center p-3 pb-5">
    <button class="btn btn-primary btn-lg shadow" (click)="shared.navigate('contacts')">
      Contatta lo studio
    </button>
  </div>
</div>
