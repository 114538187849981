
import { NavbarComponent } from './navbar/navbar.component';
import { FooterComponent } from './footer/footer.component';
import { Component, NgZone, OnInit } from '@angular/core';
import { RouterOutlet } from '@angular/router';
import { Title } from '@angular/platform-browser';
import { Router, ActivatedRoute } from '@angular/router';

@Component({
  selector: 'app-root',
  imports: [RouterOutlet, NavbarComponent, FooterComponent],
  templateUrl: './app.component.html',
  styleUrl: './app.component.css'
})
export class AppComponent implements OnInit {
  title = 'albertotoscani.it';
  loading: boolean = true;
  currentUrl = "";
  private loadListener: (() => void) | null = null;

  constructor(
    private titleService: Title, 
    private ngZone: NgZone,
    private router: Router
  ) {
    this.setTitle();
    this.currentUrl = this.router.url;
    //console.log(this.router.url)
  }

  ngOnInit(): void {
    if (document.readyState === 'complete') {
      this.onPageLoad();
    } else {
      window.addEventListener('load', () => this.onPageLoad());
    }
  }

  ngOnDestroy(): void {
    if (this.loadListener) {
      document.removeEventListener('DOMContentLoaded', this.loadListener);
    }
  }

  setTitle(): void {
    this.titleService.setTitle($localize`${this.title}`);
  }

  onPageLoad(): void {
    setTimeout(() => {
      const loader = document.getElementById('loader');
      if (loader) {
        loader.classList.add('fade-out');
  
        // Attendi la fine della transizione prima di nascondere l'elemento
        loader.addEventListener('transitionend', () => this.loading = false, { once: true });
      }
    }, 1000);
  }

  printLogo() {
    console.log(`

                                                        
                                                        
                                                        
                                                        
                             &B&                        
                            &#G5B                       
                          &###G5Y5&                     
                            &&BG#&&&&                   
                       &##&&& &&BG555B                  
                      &#######G5555555G                 
                    &   &&&###G555PB&&&#B               
                  &###&&&    &BB&&&#BP5555#             
                 &&########&& &#BP55555555P#            
               &&    &&#######G555555PG#&&&#GB          
             &#####&&    &&###G55PG#&&&#GP5555P&        
            &##########&&    &##&&&#GP555555555P#       
               &&&#########&& &BGP555555555PB&          
                    &&########G55555555PB&              
                        &&####G5555GB&                  
                            &&GG#&             
                            
                            
                      [albertotoscani.it]                         
                                                        
                                                        
                                                              
      `);
  }
}
