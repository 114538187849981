import { ViewportScroller } from '@angular/common';
import { Component, OnInit } from '@angular/core';
import { collab } from '../common/data';
import { Collab } from '../common/interfaces';
import { SharedService } from '../shared.service';

@Component({
  selector: 'app-collab',
  standalone: true,
  imports: [],
  templateUrl: './collab.component.html',
  styleUrl: './collab.component.css'
})
export class CollabComponent implements OnInit {
  collab: Collab[] = collab
  
  constructor(
    private viewportScroller: ViewportScroller,
    private shared: SharedService
  ) {
    this.viewportScroller.scrollToPosition([0, 0]);
  }

  ngOnInit(): void {
    this.shared.updateActiveLink();
  }
}
