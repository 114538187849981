
import { Component, OnInit} from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { SharedService } from '../shared.service';

@Component({
  selector: 'app-navbar',
  imports: [],
  templateUrl: './navbar.component.html',
  styleUrl: './navbar.component.css'
})
export class NavbarComponent implements OnInit {
  activeLink = '';
  isNavbarShown: boolean = false;

  constructor(
    private router: Router,
    private route: ActivatedRoute,
    public shared: SharedService
  ){
    
  }

  ngOnInit() {
    setTimeout(() => {
      this.activeLink = this.router.url.replace('/', '');
    }, 0);
  }

  onClick(event: any) {
    //console.log(event);
  }

  navigate(dest: string) {
    this.activeLink = dest;
    this.router.navigate([dest]);
    this.triggerCloseButtonClick();
    this.router.events.subscribe(
      () => {
        this.activeLink = this.router.url.replace('/', '');
      }
    )
  }

  triggerCloseButtonClick(): void {
    const closeButton = document.querySelector<HTMLButtonElement>('.btn-close');
    if (closeButton) {
        closeButton.click();
    } else {
        console.warn("Bottone di chiusura non trovato.");
    }
  } 
}
