import { ViewportScroller } from '@angular/common';
import { Component, OnInit } from '@angular/core';
import { SharedService } from '../shared.service';

@Component({
  selector: 'app-contacts',
  imports: [],
  templateUrl: './contacts.component.html',
  styleUrl: './contacts.component.css'
})
export class ContactsComponent implements OnInit {
  constructor(
    private viewportScroller: ViewportScroller,
    private shared: SharedService
  ) {
    this.viewportScroller.scrollToPosition([0, 0]);
  }

  ngOnInit(): void {
    this.shared.updateActiveLink();
  }

  showMap(id: string): void {
    const placeholder = document.getElementById(id);
    if (placeholder) {
      placeholder.style.display = 'none'; // Nasconde il placeholder
      const iframe = placeholder.nextElementSibling as HTMLIFrameElement;
      if (iframe) {
        iframe.classList.remove('d-none'); // Mostra la mappa
      }
    }
  }
}
