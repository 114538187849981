<h1 class="display-4 fw-bold my-3 lh-1 text-body-emphasis m-3">
    Privacy Policy
</h1>

<p class="small m-3">
    Ultimo aggiornamento: 27 febbraio 2025
</p>

<div class="container-fluid px-5 py-3 text-justify">
    <p class="lh-1 mb-3">
        Il presente sito web <a href="https://albertotoscani.it" target="_blank">albertotoscani.it</a> non raccoglie,
        archivia o utilizza alcun dato personale o informazioni di navigazione degli utenti. Tuttavia, il sito incorpora
        servizi di terze parti che potrebbero raccogliere dati, come Google Maps.
    </p>
    <h5 class="fw-bold my-3 lh-1 mb-3">
        Raccolta dei dati
    </h5>
    <p class="lh-1 mb-3">
        Non vengono raccolti dati personali direttamente da questo sito. Non utilizziamo moduli di contatto, strumenti
        di analisi, cookie proprietari o altri mezzi di tracciamento.
    </p>
    <h5 class="fw-bold my-3 lh-1 mb-3">
        Google Maps
    </h5>
    <p class="lh-1 mb-3">
        Il sito incorpora mappe interattive fornite da Google Maps. L'uso di questo servizio potrebbe comportare la
        raccolta di dati da parte di Google, come l'indirizzo IP e informazioni sul dispositivo dell'utente. Google
        potrebbe anche impostare cookie per il proprio funzionamento o per scopi di analisi e personalizzazione.
    </p>
    <p class="lh-1 mb-3">
        L'uso di Google Maps è regolato dalla <a target="_blank" href="https://policies.google.com/privacy">Privacy Policy di Google</a>: se non desideri che Google raccolga informazioni tramite l'uso delle mappe, puoi disabilitare i cookie di terze
        parti dalle impostazioni del tuo browser o evitare di interagire con la mappa.
    </p>
    <h5 class="fw-bold my-3 lh-1 mb-3">
        Uso dei cookie
    </h5>
    <p class="lh-1 mb-3">
        Il nostro sito non utilizza cookie propri. Tuttavia, i servizi di terze parti integrati, come Google Maps,
        potrebbero impostare cookie per il loro funzionamento. Per maggiori informazioni, consulta la <a target="_blank" href="https://policies.google.com/technologies/cookies">Cookie Policy di
        Google</a>.
    </p>
    <h5 class="fw-bold my-3 lh-1 mb-3">
        Servizi di terze parti
    </h5>
    <p class="lh-1 mb-3">
        Oltre a Google Maps, il nostro sito non impiega altri servizi di terze parti per la raccolta o il monitoraggio
        dei dati degli utenti.
    </p>
    <h5 class="fw-bold my-3 lh-1 mb-3">
        Modifiche alla privacy policy
    </h5>
    <p class="lh-1 mb-3">
        Ci riserviamo il diritto di modificare questa Privacy Policy in qualsiasi momento. Eventuali aggiornamenti
        verranno pubblicati su questa pagina.
    </p>
    <h5 class="fw-bold my-3 lh-1 mb-3">
        Contatti
    </h5>
    <p class="lh-1 mb-3">
        Per qualsiasi domanda relativa a questa Privacy Policy, puoi contattarci all'indirizzo <a target="_blank" href="nutrizionista@albertotoscani.it">nutrizionista&#64;albertotoscani.it</a>.
    </p>
</div>