import { Injectable, OnInit } from '@angular/core';
import { Router } from '@angular/router';

@Injectable({
  providedIn: 'root'
})
export class SharedService implements OnInit{
  public activeLink = 'default';

  constructor(
    private router: Router
  ) { 

  }
  ngOnInit(): void {
    setTimeout(() => {
      this.updateActiveLink();
    }, 0);
  }

  public updateActiveLink(): void {
    this.activeLink = this.router.url.replace('/', '');
    if(this.activeLink.includes('member')) {
      console.log("culo")
      this.activeLink = 'member'
    }
  }

  public navigate(dest: string) {
    this.activeLink = dest;
    this.router.navigate([dest]);
    this.triggerCloseButtonClick();
    this.router.events.subscribe(
      () => {
        this.activeLink = this.router.url.replace('/', '');
      }
    )
  }

  triggerCloseButtonClick(): void {
    const closeButton = document.querySelector<HTMLButtonElement>('.btn-close');
    if (closeButton) {
        closeButton.click();
    } else {
        console.warn("Bottone di chiusura non trovato.");
    }
  } 
}
