export const certificates = [
    {
      title: 'Worldwide International Sport Nutrition Summit',
      link: 'https://albertotoscani.it/images/certificates/0%20WorldwidE-International-Sport-Nutrition-Summit-1030x731.png',
    },
    {
      title: 'Alimentazione in Palestra: Dieta ed Integrazione',
      link: 'https://albertotoscani.it/images/certificates/Alimentazione-in-Palestra-Dieta-ed-Integrazione.png',
    },
    {
      title: 'Worldwide International Sport Nutrition Conference',
      link: 'https://albertotoscani.it/images/certificates/WorldwidE-International-Sport-Nutrition-Conference-1030x731.png',
    },
    {
      title: 'Sistema Immunitario e Meccanismi di Risposta',
      link: 'https://albertotoscani.it/images/certificates/Sistema-Immunitario-e-Meccanismi-di-Risposta.png',
    },
    {
      title: 'Ruolo del Nutrizionista nel Calcio',
      link: 'https://albertotoscani.it/images/certificates/Ruolo-del-Nutrizionista-nel-Calcio.png',
    },
    {
      title: 'Reverse Diet',
      link: 'https://albertotoscani.it/images/certificates/Reverse-Diet.png',
    },
    /*
    {
      title: 'Nutrizione durante Terapie Oncologiche',
      link: 'https://albertotoscani.it/images/certificates/Nutrizione-durante-Terapie-Oncologiche.png',
    },
    */
    /*
    {
      title: 'Mindful Eating',
      link: 'https://albertotoscani.it/images/certificates/Mindful-Eating.png',
    },
    */
    {
      title: 'Micronutrizione ad alto impatto',
      link: 'https://albertotoscani.it/images/certificates/Micronutrizione-ad-alto-impatto.png',
    },
    {
      title: 'Melatonina e Magnesio - Disturbi del Mantenimento del Sonno',
      link: 'https://albertotoscani.it/images/certificates/Melatonina-e-Magnesio-Disturbi-del-Mantenimento-del-Sonno.png',
    },
    {
      title: 'Interpretazione casi pratici Idratazione e Performance',
      link: 'https://albertotoscani.it/images/certificates/Interpretazione-casi-pratici-Idratazione-e-Performance.png',
    },
    {
      title: 'Immunita e Microbiota nello Sportivo',
      link: 'https://albertotoscani.it/images/certificates/Immunita-e-Microbiota-nello-Sportivo.png',
    },
    {
      title: 'Gestione nutrizionale dei Fighters',
      link: 'https://albertotoscani.it/images/certificates/Gestione-nutrizionale-dei-Fighters.png',
    },
    /*
    {
      title: 'Certificazione Scuola di Nutrizione ed Integrazione nello Sport',
      link: 'https://albertotoscani.it/images/certificates/Certificazione-Scuola-di-Nutrizione-ed-Integrazione-nello-Sport.png',
    },
    */
    {
      title: 'Bioimpedenza: tecnologie e applicazioni',
      link: 'https://albertotoscani.it/images/certificates/Bioimpedenza-tecnologie-e-applicazioni.png',
    },
    {
      title: 'Bioimpedenziometria: focus on SportFitness',
      link: 'https://albertotoscani.it/images/certificates/Bioimpedenziometria-focus-on-SportFitness.png',
    },
    {
      title: 'ESNS Digital Meeting Update in Sport Nutrition',
      link: 'https://albertotoscani.it/images/certificates/ESNS-Digital-Meeting-Update-in-Sport-Nutrition.png',
    },
    {
      title: 'Dieta Chetogenica - Corso di confronto e dialogo',
      link: 'https://albertotoscani.it/images/certificates/Dieta-Chetogenica-Corso-di-confronto-e-dialogo.png',
    },
    {
      title: 'Cambiamento Regime Alimentare e Dropout',
      link: 'https://albertotoscani.it/images/certificates/Cambiamento-Regime-Alimentare-e-Dropout.png',
    },
    {
      title: 'Dimagrimento Rapido',
      link: 'https://albertotoscani.it/images/certificates/Dimagrimento-Rapido.png',
    },
    /*
    {
      title: 'Antiossidanti e Sport',
      link: 'https://albertotoscani.it/images/certificates/Antiossidanti-e-Sport.png',
    },
    */
    {
      title: 'Alimentazione per Mamma e Bambino',
      link: 'https://albertotoscani.it/images/certificates/Alimentazione-per-Mamma-e-Bambino.png',
    },
    /*
    {
      title: 'Alterazioni biochimiche nutrizione clinica',
      link: 'https://albertotoscani.it/images/certificates/Alterazioni-biochimiche-nutrizione-clinica.png',
    },
    */
    {
      title: 'Alimentazione per massimizzare la performance in gara',
      link: 'https://albertotoscani.it/images/certificates/Alimentazione-per-massimizzare-la-performance-in-gara.png',
    },
  ];