import { Collab, Member } from './interfaces';

export const team: Member[] = [
  {
    id: 'alberto-toscani',
    name: 'Dott. Alberto Toscani',
    descr: 'Nutrizione sportiva amatoriale ed agonistica.',
    imageUrl: 'https://albertotoscani.it/images/team/atoscani-removebg.png',
    quote: '',
    paragraphs: [
      'Mi chiamo Alberto Toscani e gestisco il Team Nutrizionale Toscani.',
      "Mi occupo di nutrizione sportiva, dall'amatoriale fino all'agonista lavoreremo insieme per raggiungere l'obiettivo a cui si vuole arrivare, sia esso legato all'estetica o all'ottimizzazione della performance sportiva o al raggiungimento della propria migliore condizione.",
      'Non sarà un percorso solitario ma lavoreremo insieme, fianco a fianco, così da arrivare nel modo più lineare e trasparente possibile al risultato desiderato.',
    ],
    cvUrl: 'https://albertotoscani.it/images/docs/cv-toscani.pdf',
    mailto: 'sample@email.it',
  },
  {
    id: 'valentina-antoniotti',
    name: 'Dott.ssa Valentina Antoniotti',
    descr:
      'Nutrizione legata a problematiche endocrinologiche diagnosticate, disturbi alimentari e nutrizione pediatrica.',
    imageUrl: 'https://albertotoscani.it/images/team/v-antoniotti-removebg.png',
    quote: '',
    paragraphs: [
      'Io sono Valentina Antoniotti, mi occupo della nutrizione legata a problematiche endocrinologiche già diagnosticate, tra cui diabete, ipertensione, ipotiroidismo, ipertiroidismo, ovaio policistico.',
      'Oltre a questo gestisco anche i disturbi alimentari e la nutrizione pediatrica.',
      'Insieme costruiremo un percorso con continui confronti in presenza e telefonici in modo da sistemare l’alimentazione a seconda delle necessità.',
    ],
    cvUrl: 'https://albertotoscani.it/images/docs/cv-antoniotti.pdf',
    mailto: 'sample@email.it',
  },
  {
    id: 'valentina-zabarini',
    name: 'Dott.ssa Valentina Zabarini',
    descr:
      'Nutrizione legata a problematiche gastrointestinali, nutrizione in gravidanza e PMA, dieta FODMAP.',
    imageUrl: 'https://albertotoscani.it/images/team/v-zabarini-removebg.png',
    quote: '',
    paragraphs: [
      'Sono Valentina Zabarini, mi occupo della nutrizione legata a problematiche gastrointestinali proponendo strategia alimentari come ad esempio la dieta FODMAP.',
      'Gestisco anche la parte nutrizionale per il pre-durante-post gravidanza e per la PMA (procreazione medico assistita).',
      'Grazie a piani personalizzati e confronti su varie ricette troveremo il modo di gestire le diverse situazioni.',
    ],
    cvUrl: 'https://albertotoscani.it/images/docs/cv-zabarini.pdf',
    mailto: 'sample@email.it',
  },
];

export const collab: Collab[] = [
    {
        id: 1,
        title: 'M.A.S.T. - Martial Arts Santhià Team A.S.D. (TO)',
        descr: 'Biologo Nutrizionista Sportivo',
        collabUrl: 'https://www.facebook.com/martialartssanthiateamasd',
        logoUrl: 'https://albertotoscani.it/images/collab/M.A.S.T..png',
        startDate: '2019',
        endDate: 'in corso'
    },
    {
        id: 2,
        title: 'F.C. Pro Vercelli (VC)',
        descr: 'Data Analyst per la composizione corporea',
        collabUrl: 'https://www.fcprovercelli.it/',
        logoUrl: 'https://albertotoscani.it/images/collab/Pro%20Vercelli.jpg',
        startDate: '2019',
        endDate: '2022'
    },
    {
        id: 3,
        title: 'FitnessLab81 di Beppe Sacco (Personal Trainer)',
        descr: 'Affiancamento nutrizionale alla preparazione atletica',
        collabUrl: 'https://fitnesslab81.it/',
        logoUrl: 'https://albertotoscani.it/images/collab/FitnessLab81.png',
        startDate: '2020',
        endDate: 'in corso'
    },
    {
        id: 4,
        title: 'Novara Football Club (NO)',
        descr: 'Biologo Nutrizionista Sportivo (vittoria campionato e passaggio da serie D a serie C)',
        collabUrl: 'https://www.instagram.com/novarafootballclub/',
        logoUrl: 'https://albertotoscani.it/images/collab/Novara%20FC.png',
        startDate: '2021',
        endDate: '2022'
    },
    /*
    {
        id: 5,
        title: 'Alice Martinello (Personal Trainer)',
        descr: 'Affiancamento nutrizionale a dimagrimento e ricomposizione corporea nella donna',
        collabUrl: 'https://www.instagram.com/alice.m.fit/',
        logoUrl: './favicon-96x96.png',
        startDate: '2023',
        endDate: 'in corso'
    },
    */
    {
        id: 6,
        title: 'Andrea Di Falco (Personal Trainer)',
        descr: 'Affiancamento nutrizionale alla preparazione atletica d\'élite',
        collabUrl: 'https://www.instagram.com/adf__coach/',
        logoUrl: 'https://albertotoscani.it/images/collab/Elite%20training%20vercelli.png',
        startDate: '2023',
        endDate: 'in corso'
    },
    {
        id: 7,
        title: 'Marco Zanchetta (Personal Trainer \"Invictus\")',
        descr: 'Affiancamento nutrizionale a dimagrimento e ricomposizione corporea in presenza e online',
        collabUrl: 'https://www.instagram.com/mz_workout_official/',
        logoUrl: 'https://albertotoscani.it/images/collab/Marco%20Zanchetta.jpg',
        startDate: '2023',
        endDate: 'in corso'
    },
]