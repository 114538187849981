import { Component, OnInit } from '@angular/core';
import { Member } from '../common/interfaces';
import { Router } from '@angular/router';
import { team } from '../common/data';
import { ViewportScroller } from '@angular/common';
import { SharedService } from '../shared.service';

@Component({
  selector: 'app-team',
  standalone: true,
  imports: [],
  templateUrl: './team.component.html',
  styleUrl: './team.component.css'
})
export class TeamComponent implements OnInit {
  team: Member[] = team;
  constructor(
    private router: Router,
    private viewportScroller: ViewportScroller,
    public shared: SharedService
  ) {
    this.viewportScroller.scrollToPosition([0, 0]);
  }

  ngOnInit(): void {
    this.shared.updateActiveLink();
  }

  toggleParagraph(paragraphId: string) {
    document.getElementById(paragraphId)!.classList.toggle('show');
  }

  navigateToMemberPage(route: string) {
    this.router.navigate(['team/member/' + route]);
  }
}
