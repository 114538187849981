<!-- Footer -->
<footer class="text-center text-lg-start my-0 bg-main" data-bs-theme="dark">
  <!-- Grid container -->
    <!-- Section: Social media -->
<!-- Section: Social media -->
<section class="container text-center pt-4">
  <div class="d-flex justify-content-center flex-wrap gap-3">
    <!-- Facebook -->
    <a
      data-mdb-ripple-init
      class="btn text-white btn-floating shadow circle"
      style="background-color: #3b5998;"
      href="https://www.facebook.com/albertotoscaninutrizionista"
      target="_blank"
      role="button"
    >
      <i class="fab fa-facebook"></i>
    </a>

    <!-- Instagram -->
    <a
      data-mdb-ripple-init
      class="btn text-white btn-floating shadow circle"
      style="background-color: #ac2bac;"
      href="https://www.instagram.com/albertotoscani.nutrizionista"
      target="_blank"
      role="button"
    >
      <i class="fab fa-instagram"></i>
    </a>

    <!-- YouTube -->
    <a
      data-mdb-ripple-init
      class="btn text-white btn-floating shadow circle"
      style="background-color: #ff0033;"
      href="https://www.youtube.com/@nutrizionesportsalute-nss9606"
      target="_blank"
      role="button"
    >
      <i class="fab fa-youtube"></i>
    </a>

    <!-- Linkedin -->
    <a
      data-mdb-ripple-init
      class="btn text-white btn-floating shadow circle"
      style="background-color: #0082ca;"
      href="https://www.linkedin.com/in/alberto-toscani-781713130/"
      target="_blank"
      role="button"
    >
      <i class="fab fa-linkedin-in"></i>
    </a>
  </div>
</section>
<!-- Section: Social media -->

  
    <!-- Fiscal position -->
    <div class="text-center p-4">
      <div class="">
        Dott. Alberto Toscani 
      </div>
      <div class="">
        Biologo Nutrizionista Sportivo d'Élite
      </div>
      <div class="small">
        P.IVA: IT02599310030
      </div>
      <div class="small">
        Ordine dei Biologi PLV – PLV_A2886
      </div>
      <div class="small">
        <a target="_blank" href="mailto:nutrizionista@albertotoscani.it">nutrizionista&#64;albertotoscani.it</a>
      </div>  
    </div>
    <!-- Fiscal position -->

    <!-- Copyright -->
    <div class="text-center pt-4">      
      <i class="fa-solid fa-apple-whole"></i>&nbsp;<b>albertotoscani.it</b>  
    </div>
    <!-- Copyright -->

    <div class="text-center">
      &#169;{{year}} Alberto Toscani | <a class="clickable" (click)="shared.navigate('privacy-policy')">Privacy Policy</a>
    </div>
    <div class="text-center pb-4">
      Designed by <a href="https://lrossi.xyz" target="_blank">Lorenzo Rossi</a>
    </div>
  </footer>
  <!-- Footer -->