<h1 class="display-4 fw-bold lh-1 text-body-emphasis m-3">
  Collaborazioni
</h1>

<div class="container-fluid">
  <h2 class="fw-bold lh-1 m-3 my-5">
    Cronologia delle collaborazioni
  </h2>
  <div class="container my-5">
    <div class="row">
      <div class="col">
        <ul class="timeline-3">
          @for(item of collab.slice().reverse(); track $index) {
          <li>
            <div class="row">
              <div class="col-md-2">
                <a href="{{item.collabUrl}}" target="_blank">
                  <img src="{{item.logoUrl}}" alt="{{item.title}}" class="img-fluid square-img">
                </a>
              </div>
              <div class="col-md-10">
                <h4>
                  {{item.title}}
                </h4>
                <p class="small">
                  {{item.startDate}} - {{item.endDate}}
                </p>
                <p class="">
                  {{item.descr}}
                </p>
              </div>
            </div>
          </li>
          }
        </ul>
      </div>
    </div>
  </div>
</div>