<!--
=========================================================================================
          Divisore con hero ottimizzato per SEO
=========================================================================================
-->
<div class="container-fluid col-xl-10 col-xxl-8 px-4 py-5">
  <div class="row align-items-center g-lg-5 py-5">
    <div class="col-lg-7 text-center text-lg-start">
      <h1 class="display-3 fw-bold lh-1 text-body-emphasis mb-3">
        Alberto Toscani
      </h1>
      <h1 class="display-5 fw-bold lh-1 text-body-emphasis mb-3">
        Biologo Nutrizionista Sportivo d'Élite
      </h1>
      <h2 class="text-body-emphasis my-4">
        Massimizza la tua performance con la nutrizione scientifica
      </h2>
      <p class="fs-4">
        Come esperto in nutrizione sportiva, aiuto atleti professionisti e amatori ad ottimizzare le loro prestazioni attraverso strategie nutrizionali avanzate e personalizzate. La mia esperienza si basa su solide competenze scientifiche e anni di lavoro con sportivi di alto livello.
      </p>
      <p class="fs-4">
        A capo di un team nutrizionale multidisciplinare, garantisco un supporto completo: mentre io mi occupo in modo specifico della nutrizione sportiva, il mio team segue anche altri aspetti dell'alimentazione, dal benessere generale al miglioramento metabolico.
      </p>
      <p class="fs-4">
        Grazie a un approccio su misura e aggiornamenti costanti, trasformiamo ogni analisi in un piano nutrizionale efficace per il massimo risultato.
      </p>
      <br>
      <p class="fs-4 text-center">
        <button class="btn btn-primary btn-lg shadow" (click)="shared.navigate('contacts')">
          Contattami!
        </button>
      </p>
    </div>
    <div class="col-md-10 mx-auto col-lg-5">
      <div class="card border-0 layers">
        <img class="img-fluid bg-image" src="https://albertotoscani.it/images/logos/albertotoscani-logo.png" alt="Logo Studio Toscani" loading="lazy" />
        <img class="img-fluid member-image" src="https://albertotoscani.it/images/team/atoscani-removebg.png" alt="Dott. Alberto Toscani, nutrizionista" loading="lazy" />
      </div>
    </div>
  </div>
</div>

<!--
=========================================================================================
          Divisore con caption SEO
=========================================================================================
-->
<div class="container-fluid text-center p-5 bg-main">
  <h2 class="fw-bold lh-1 mb-3">
    Nutrizione e sport per uno stile di vita sano
  </h2>
  <p class="lh-1 mb-3 fs-4">
    Scopri come raggiungere il tuo benessere con un piano nutrizionale personalizzato!
  </p>
</div>

<!--
=========================================================================================
          Servizi - Ottimizzati con Lazy Loading e SEO
=========================================================================================
-->
<div class="container-fluid text-center bg-dark-grey py-3 px-4">
  <div class="row">
    <div class="col-lg-3 p-3">
      <div class="card text-bg-dark border border-0 img-service shadow square clickable" (click)="shared.navigate('team')">
        <img class="img-fluid rounded shadow" src="https://albertotoscani.it/images/home/team-removebg.png" alt="Il team di esperti nutrizionisti" loading="lazy" />
        <div class="card-img-overlay d-flex justify-content-center align-items-center">
          <div>
            <h2 class="card-title text-center">
              Team nutrizionale
            </h2>
            <h5 class="card-title text-center">
              Conosci i professionisti dello Studio Toscani
            </h5>
          </div>
        </div>
      </div>
    </div>

    <div class="col-lg-3 p-3">
      <div class="card text-bg-dark border border-0 img-service shadow square clickable" (click)="shared.navigate('collab')">
        <img class="img-fluid rounded shadow" src="https://albertotoscani.it/images/home/collab.jpg" alt="Collaborazioni con specialisti del settore" loading="lazy" />
        <div class="card-img-overlay d-flex justify-content-center align-items-center show-on-hover">
          <div>
            <h2 class="card-title text-center">Collaborazioni</h2>
            <h5 class="card-title text-center">
              Partnership con esperti di nutrizione e sport
            </h5>
          </div>
        </div>
      </div>
    </div>

    <div class="col-lg-3 p-3">
      <div class="card text-bg-dark border border-0 img-service shadow square clickable" (click)="shared.navigate('services')">
        <img class="img-fluid rounded shadow" src="https://albertotoscani.it/images/home/services.jpg" alt="Servizi di consulenza nutrizionale" loading="lazy" />
        <div class="card-img-overlay d-flex justify-content-center align-items-center show-on-hover">
          <div>
            <h2 class="card-title text-center">Servizi</h2>
            <h5 class="card-title text-center">
              Consulenze personalizzate per il tuo benessere
            </h5>
          </div>
        </div>
      </div>
    </div>

    <div class="col-lg-3 p-3">
      <div class="card text-bg-dark border border-0 img-service shadow square clickable" (click)="shared.navigate('community')">
        <img class="img-fluid rounded shadow" src="https://albertotoscani.it/images/home/community.jpg" alt="Comunità e contenuti per la tua nutrizione" loading="lazy" />
        <div class="card-img-overlay d-flex justify-content-center align-items-center show-on-hover">
          <div>
            <h2 class="card-title text-center">Community</h2>
            <h5 class="card-title text-center">
              Articoli, consigli e approfondimenti esclusivi
            </h5>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>
