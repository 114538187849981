<h1 class="display-4 fw-bold lh-1 text-body-emphasis m-3">
    Servizi
</h1>

<div class="container-fluid text-center p-5 elite">
    <h2 class="fw-bold lh-1 mb-3">
        Élite Excellence Program
    </h2>
    <p class="lh-1 mb-3 fs-4">
        Affiancamento nutrizionale sportivo per atleti amatoriali e agonisti, con supporto per gli agonisti in periodo
        gare
    </p>
    <p class="lh-1 mb-3 fs-4">
        <i class="fst-italic">Il tuo corpo merita il massimo.</i>
    </p>
    <p class="lh-1 mb-3 fs-4">
        <button class="btn btn-primary btn-lg" (click)="scrollToElement('approach')">Scopri di più</button>
    </p>
</div>


<div class="container-fluid text-center p-5">
    <h2 class="fw-bold lh-1 mb-3">
        Standard Nutrition Program
    </h2>
    <p class="lh-1 mb-3 fs-4">
        Affiancamento nutrizionale dedicato a gestire la parte alimentare legata a problematiche endocrinologiche (come
        patologie diagnosticate dal medico quali diabete, problemi alla tiroide, ipercolesterolemia, ipertensione, ovaio
        policistico, etc), nutrizione pediatrica, gestione parte nutrizionale nei disturbi alimentari.
    </p>
    <p class="lh-1 mb-3 fs-4">
        <i class="fst-italic">Per salute e benessere costanti.</i>
    </p>
    <p class="lh-1 mb-3 fs-4">
        <button class="btn btn-primary btn-lg" (click)="scrollToElement('approach')">Scopri di più</button>
    </p>
</div>


<div class="container-fluid text-center p-5 bg-main">
    <h2 class="fw-bold lh-1 mb-3">
        Percorsi di prima visita e controlli nutrizionali
    </h2>
    <p class="lh-1 mb-3 fs-4">
        Incontri volti all'educazione ed equilibrio nutrizionale in ambito di dimagrimento, pre-durante-post gravidanza,
        PMA (procreazione medico assistita), gestione alimentare in caso di disturbi gastrointestinali.
    </p>
    <p class="lh-1 mb-3 fs-4">
        <i class="fst-italic">A piccoli passi verso il tuo obiettivo.</i>
    </p>
    <p class="lh-1 mb-3 fs-4">
        <button class="btn btn-primary btn-lg" (click)="scrollToElement('approach')">Scopri di più</button>
    </p>
</div>

<div class="container-fluid p-3 pt-5">
    <h3 id="approach" class="fw-bold lh-1">
        Il nostro approccio nutrizionale
    </h3>
    <ul>
        <li>
            Invio di documentazione per creare cartella e background;
        </li>
        <li>
            Prima visita in cui vengono approfonditi i dati raccolti, si prendono i dati antropometrici (a seconda del
            percorso
            se standard o sportivo si andrà più nel dettaglio della composizione corporea);
        </li>
        <li>
            Feedback via whatsapp e check continui nel caso dei percorsi di affiancamento nutrizionale;
        </li>
        <li>
            Elaborazione schema personalizzato e modifiche nel tempo per migliorare approccio e strategia anche
            confrontandosi
            direttamente con i gusti e le esigenze della persona;
        </li>
        <li>
            Visite di controllo in cui si monitora l'andamento e si possono segnalare le difficoltà avute, così da
            intervenire
            in modo bilanciato e mirato per la persona per proseguire al meglio il proprio percorso nutrizionale.
        </li>
    </ul>
</div>
<div class="container-fluid p-3 pb-5">
    <h3 class="fw-bold lh-1">
        Solo per sportivi
    </h3>
    <ul>
        <li>
            Stima approfondita della composizione corporea (distribuzione massa grassa, massa muscolare, qualità
            muscolare,
            idratazione);
        </li>
        <li>
            Check periodici di aggiornamento, sia in presenza sia online, della strategia nutrizionale a seconda degli
            obiettivi;
        </li>
        <li>
            Supporto per gli agonisti vicino a gare o trasferte;
        </li>
        <li>
            Elaborazione piano integrazione sportiva (attenzione, verrà valutato in modo molto specifico e caso per caso
            e solo
            se necessaria).
        </li>
    </ul>
</div>

<h1 class="display-4 fw-bold lh-1 text-body-emphasis m-3">
    Servizi in collaborazione
</h1>

<div class="container-fluid text-center p-5 mzworkout">
    <h2 class="fw-bold lh-1">
        Programma <i class="fw-bold fst-italic">Allenamento + Nutrizione ONLINE</i>
    </h2>
    <p class="lh-1 mb-3 fs-4 py-3 pt-4">
        Pacchetto di <b>3 o 6 mesi</b> in collaborazione con <b>Marco Zanchetta (PT Invictus)</b>
    </p>
    <a href="https://www.instagram.com/mz_workout_official/" target="_blank">
        <img src="https://albertotoscani.it/images/collab/Marco%20Zanchetta.jpg" class="square-img m-3" alt="Marco Zanchetta (PT Invictus) su Instagram">
    </a>
    <p class="lh-1 mb-3 fs-4">
        <b>3 mesi</b> con personal trainer e 4 controlli nutrizionali
    </p>
    <p class="lh-1 mb-3 fs-4">
        <b>6 mesi</b> con personal trainer e 8 controlli nutrizionali
    </p>
</div>

<div class="container-fluid text-center p-5 adf">
    <h2 class="fw-bold lh-1">
        Programma <i class="fw-bold fst-italic">Preparazione Atleta Élite</i>
    </h2>
    <p class="lh-1 mb-3 fs-4 py-3 pt-4">
        Percorso di <b>12 mesi di allenamento e nutrizione</b>, in collaborazione con <b>Andrea Di Falco (PT)</b>
    </p>
    <a href="https://www.instagram.com/adf__coach/" target="_blank" >
        <img src="https://albertotoscani.it/images/collab/Elite%20training%20vercelli.png" class="square-img m-3" alt="Andrea Di Falco (PT) su Instagram">
    </a>
    
    <p class="lh-1 mb-3 fs-4">
        <b>12 mesi</b> di programmazione con una seduta di personal training al mese
    </p>
    <p class="lh-1 mb-3 fs-4">
        <b>16 controlli nutrizionali</b> 
    </p>
    <p class="lh-1 mb-3 fs-4">
        <i class="fst-italic">Extra:</i>&nbsp;<b>analisi della composizione corporea</b> pre-gara o a fine percorso 
    </p>
    <p class="lh-1 mb-3 fs-4">
        <i class="fst-italic">Extra:</i>&nbsp;<b>ulteriori controlli</b> in periodo gara (per agonisti) 
    </p>

</div>

