<h1 class="display-4 fw-bold lh-1 text-body-emphasis m-3">
    {{member.name}}
</h1>

<div class="container-fluid col-xl-10 col-xxl-8 px-4 pb-5">
    <div class="row align-items-center g-lg-5 py-5">
      <div class="col-lg-7 text-center text-lg-start">
        <p class="fs-4">
          <a target="_blank" href="{{member.cvUrl}}">Curriculum</a>
        </p>
        @for (paragraph of member.paragraphs; track $index) {
            <p class="fs-4">
                {{paragraph}}
            </p>
        }
        <div class="text-center py-5">
          <p class="fs-4">
            Compila il form per essere ricontattat*:
          </p>
          <a target="_blank" href="https://form.jotform.com/242481390494057">
            <button type="button" class="btn btn-primary btn-lg shadow">Vai al form</button>
          </a>
        </div>
      </div>
      <div class="col-md-10 mx-auto col-lg-5">
        <div class="card border-0 layers">
          <!-- Immagine di sfondo (logo), nascosta di default -->
          <img class="img-fluid bg-image" src="https://albertotoscani.it/images/logos/albertotoscani-logo.png" alt="dottor toscani"/>
          <!-- Immagine del membro, sempre in primo piano -->
          <img class="img-fluid member-image" src="{{member.imageUrl}}" alt="Dott. Alberto Toscani"/>
        </div>
      </div>
    </div>
  </div>

  @if(member.id == 'alberto-toscani') {
    <h2 class="fw-bold lh-1 m-3 my-5 text-center">Le mie certificazioni</h2>
    <div class="container-fluid text-center bg-dark-grey py-3 px-4">
      <div class="row">
          @for (certificate of certificates; track $index) {
              <div class="col-lg-3 p-3">
                  <div class="card text-bg-dark border border-0 img-service shadow">
                      <a href="{{certificate.link}}" target="_blank">
                          <img class="img-fluid rounded shadow" src="{{certificate.link}}" alt="dottor toscani" />
                          <div class="card-img-overlay d-flex justify-content-center align-items-center show-on-hover">
                              <h5 class="card-title text-center service">{{certificate.title}}</h5>
                          </div>
                      </a>
                  </div>
              </div>
          }
      </div>
  </div>
  }
