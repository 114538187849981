import { Routes } from '@angular/router';
import { HomeComponent } from './home/home.component';
import { PageNotFoundComponent } from './page-not-found/page-not-found.component';
import { CommunityComponent } from './community/community.component';
import { CollabComponent } from './collab/collab.component';
import { TeamComponent } from './team/team.component';
import { ContactsComponent } from './contacts/contacts.component';
import { ServicesComponent } from './services/services.component';
import { MemberComponent } from './member/member.component';
import { PrivacyPolicyComponent } from './privacy-policy/privacy-policy.component';

export const routes: Routes = [
    { path: '', component: HomeComponent },
    { path: 'services', component: ServicesComponent },
    { path: 'team', component: TeamComponent },
    { path: 'team/member/:id', component: MemberComponent },
    { path: 'collab', component: CollabComponent },
    { path: 'community', component: CommunityComponent },
    { path: 'contacts', component: ContactsComponent }, 
    { path: 'privacy-policy', component: PrivacyPolicyComponent }, 
    { path: 'home', redirectTo: '/', pathMatch: 'full' },
    { path: '**', component: PageNotFoundComponent },
];
