import { ViewportScroller } from '@angular/common';
import { Component, OnInit } from '@angular/core';
import { SharedService } from '../shared.service';

@Component({
  selector: 'app-services',
  imports: [],
  templateUrl: './services.component.html',
  styleUrl: './services.component.css'
})
export class ServicesComponent implements OnInit{
  constructor(
    private viewportScroller: ViewportScroller,
    private shared: SharedService
  ) {
    this.viewportScroller.scrollToPosition([0, 0]);
  }  

  ngOnInit(): void {
    this.shared.updateActiveLink();
  }

  scrollToElement(targetId: string) {
    const element = document.getElementById(targetId);
    if (element) {
      this.viewportScroller.scrollToPosition([0, element.offsetTop - 78]);
    } else {
      console.warn(`Elemento con id '${targetId}' non trovato.`);
    }
  }
}
