<h1 class="display-4 fw-bold lh-1 text-body-emphasis m-3">Contatti</h1>

<div class="container-fluid">
    <h2 class="fw-bold lh-1 m-3 my-5 text-center">Come contattarci</h2>
    <p class="lh-1 m-3 fs-5 text-center">
        Siamo a tua disposizione! Scegli il metodo di contatto che preferisci, e saremo lieti di rispondere alle tue domande.
    </p>
</div>

<div class="container">
    <div class="row justify-content-center">
        <!-- Contatto via Jotform -->
        <div class="col-lg-5 d-flex justify-content-center">
            <div class="card shadow m-3 text-center" style="max-width: 800px; width: 100%;">
                <div class="card-body">
                    <h2 class="fw-bold text-body-emphasis m-3">Modulo online</h2>
                    <h1 class="display-2 m-4">
                        <i class="fa-solid fa-file-lines" aria-hidden="true"></i>
                    </h1>
                    <p class="fs-5">Compila il modulo e ti ricontatteremo al più presto.</p>
                    <a target="_blank" href="https://form.jotform.com/242481390494057">
                        <button type="button" class="btn btn-primary btn-lg shadow m-3" aria-label="Vai al modulo online">
                            Vai al form
                        </button>
                    </a>
                </div>
            </div>
        </div>

        <!-- Contatto via WhatsApp -->
        <div class="col-lg-5 d-flex justify-content-center">
            <div class="card shadow m-3 text-center" style="max-width: 800px; width: 100%;">
                <div class="card-body">
                    <h2 class="fw-bold text-body-emphasis m-3">WhatsApp</h2>
                    <h1 class="display-2 m-4">
                        <i class="fa-solid fa-phone" aria-hidden="true"></i>
                    </h1>
                    <p class="fs-5">Scrivici su WhatsApp per una risposta più rapida!</p>
                    <a href="https://wa.me/393703193640" target="_blank">
                        <button type="button" class="btn btn-primary btn-lg shadow m-3" aria-label="Vai alla chat WhatsApp">
                            Vai a WhatsApp
                        </button>
                    </a>
                </div>
            </div>
        </div>
    </div>
</div>

<div class="container">
    <h2 class="fw-bold m-3 my-5 text-center">Come raggiungerci</h2>
    <p class="fs-4 text-center">Le visite nutrizionali si svolgono in uno dei nostri studi.</p>

    <div class="row justify-content-center pb-5">
        <!-- Studio HealthyLife360 - Cameri -->
        <div class="col-lg-4 d-flex justify-content-center">
            <div class="text-center" style="max-width: 400px; width: 100%;">
                <p class="fw-bold my-3">Studio HealthyLife360 - Cameri (NO)</p>
                <div class="ratio ratio-1x1">
                    <iframe src="https://www.google.com/maps/embed?pb=!1m14!1m8!1m3!1d5399.445800004344!2d8.66006204298531!3d45.500730488453314!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x47865ecb1890bb75%3A0xdb244848f34a1a5!2sVia%20Giuseppe%20Mazzini%2C%2046%2C%2028062%20Cameri%20NO!5e1!3m2!1sit!2sit!4v1739634376902!5m2!1sit!2sit" 
                        style="border:0;" allowfullscreen="" loading="lazy">
                    </iframe>
                </div>
            </div>
        </div>

        <!-- Studio HealthyLife - Galliate -->
        <div class="col-lg-4 d-flex justify-content-center">
            <div class="text-center" style="max-width: 400px; width: 100%;">
                <p class="fw-bold my-3">Studio HealthyLife - Galliate (NO)</p>
                <div class="ratio ratio-1x1">
                    <iframe src="https://www.google.com/maps/embed?pb=!1m14!1m8!1m3!1d4560.714222575522!2d8.696783!3d45.477325!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x478658ea731c2d73%3A0x2db7afe97d9b406f!2sVia%20SS.%20Martiri%20Giusto%20e%20Aurelio%2C%2032%2C%2028066%20Galliate%20NO!5e1!3m2!1sit!2sit!4v1739633739056!5m2!1sit!2sit" 
                        style="border:0;" allowfullscreen="" loading="lazy">
                    </iframe>
                </div>
            </div>
        </div>

        <!-- Studio Rebody, Novara -->
        <div class="col-lg-4 d-flex justify-content-center">
            <div class="text-center" style="max-width: 400px; width: 100%;">
                <p class="fw-bold my-3">Studio Rebody - Novara</p>
                <div class="ratio ratio-1x1">
                    <iframe src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d11650.71449173255!2d8.607657777503233!3d45.45703102771934!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x47865a2f9675b9b5%3A0x20485a0e7951d47d!2sVia%20Giosu%C3%A8%20Borsi%2C%2015a%2C%2028100%20Novara%20NO!5e1!3m2!1sit!2sit!4v1740008402905!5m2!1sit!2sit" 
                        style="border:0;" allowfullscreen="" loading="lazy">
                    </iframe>
                </div>
            </div>
        </div>
    </div>
</div>
